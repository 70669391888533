import React, { ComponentPropsWithoutRef } from 'react';
import styled, { keyframes } from 'styled-components';

import colors from 'Static/typography/colors';

interface Props {
	tabSize?: number;
	dotSize?: number;
	visibleCount?: number;
	totalCount: number;
	activeIndex: number;
	rtl?: boolean;
	isOverlay?: boolean;
	bottom?: number;
	currentIndexTime?: number;
	onDotClick?: (index: number) => void;
	activeSlideTimer?: number;
}

export const PaginatorWrapper = styled.ul<
	Pick<Props, 'rtl' | 'isOverlay' | 'bottom'>
>`
	bottom: ${props => props.bottom}rem;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	flex-direction: ${props => (props.rtl ? 'row-reverse' : 'row')};
	position: absolute;
	margin: 0;
	padding: 0;

	list-style: none;
`;
interface PaginatorButtonProps extends ComponentPropsWithoutRef<'li'> {
	isActive: boolean;
	dotSize: number;
	tabSize: number;
	isVisited?: boolean;
	activeSlideTimer?: number;
	$hasWhiteBackground?: boolean;
	$shouldScaleDown?: boolean;
}

const progressAnimation = keyframes`
	0%{
		width: 0%;
	}
	100%{
		width: 100%;
	}
`;
export const PaginatorDot = styled.li<PaginatorButtonProps>`
	position: relative;
	overflow: hidden;
	isolation: isolate;
	transition: transform 0.5s;
	width: ${({ isActive, dotSize, tabSize }) =>
		isActive ? tabSize : dotSize}rem;
	height: ${({ dotSize }) => dotSize}rem;
	background-color: ${({ $hasWhiteBackground }) =>
		$hasWhiteBackground ? colors.WHITE : `${colors.WHITE}80`};
	transition: width 0.5s ease;
	border-radius: ${({ dotSize }) => dotSize}rem;
	margin: 0 0.1875rem;
	cursor: pointer;
	flex-shrink: 0;
	${({ $shouldScaleDown }) => $shouldScaleDown && `transform: scale(0.8);`}

	span {
		display: ${props =>
			props.activeSlideTimer && props.isActive ? 'inline-block' : 'none'};
		position: absolute;
		left: 0;
		top: 0;
		width: 0%;
		height: 100%;
		background-color: ${colors.WHITE};
		border-radius: ${({ dotSize }) => dotSize}rem;
		z-index: 10;
		opacity: 1;
		animation: ${progressAnimation}
			${({ activeSlideTimer }) => activeSlideTimer}ms ease-in forwards;
	}
`;

export const Paginator = ({
	tabSize = 3.6,
	dotSize = 8,
	totalCount,
	activeIndex,
	onDotClick,
	bottom = 3,
	activeSlideTimer,
}: Props) => {
	if (totalCount <= 1) return null;

	return (
		<PaginatorWrapper bottom={bottom}>
			{[...Array(totalCount)].map((_, i) => (
				<PaginatorDot
					tabSize={tabSize}
					dotSize={dotSize}
					isActive={i === activeIndex}
					key={`dot` + i}
					data-num={i}
					data-active={i === activeIndex}
					onClick={() => onDotClick?.(i)}
					activeSlideTimer={activeSlideTimer}
				>
					<span aria-hidden={true} key={'active' + activeIndex} />
				</PaginatorDot>
			))}
		</PaginatorWrapper>
	);
};
