/******************************
 ** Font & Font Colors
 ******************************/

const fonts = {
	AVENIR: 'Avenir, proxima-nova, arial, sans-serif',
	GRAPHIK: 'Graphik, Arial, San Fransisco, sans-serif',
	HALYARD: {
		DISPLAY:
			'halyard-display, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
		TEXT: 'halyard-text, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
	},
	FONT_WEIGHT: {
		LIGHT: 300,
		NORMAL: 400,
		MEDIUM: 500,
		SEMI_BOLD: 600,
		BOLD: 800,
		HALYARD: {
			BOLD: 700,
		},
	},
};

export default fonts;
