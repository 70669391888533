import React from 'react';

import { PaginatorDot } from 'Components/common/slickWrapper/paginator';

import { StyledDot1, StyledDotsContainer, StyledDotsList } from './style';

const getLimitDelta = (limit: any) => {
	return Math.floor(limit / 2);
};
const inFirstHalf = (index: any, limit: any) => {
	const limitDelta = getLimitDelta(limit);
	return index <= limitDelta;
};
const inLastHalf = (index: any, limit: any, total: any) => {
	const limitDelta = getLimitDelta(limit);
	return total - index - 1 <= limitDelta;
};

const calcTranslateX = (
	activeIndex: any,
	limit: any,
	total: any,
	sizeInPx: any,
	dotsMargin: number,
) => {
	const limitDelta = getLimitDelta(limit);

	if (inFirstHalf(activeIndex, limit)) return 0;
	if (inLastHalf(activeIndex, limit, total)) {
		return (total - limit) * (sizeInPx + dotsMargin);
	}

	return (activeIndex - limitDelta) * (sizeInPx + dotsMargin);
};
const isAtCenter = (currentIndex: any, activeIndex: any, limit: any) => {
	const limitDelta = getLimitDelta(limit);
	return (
		currentIndex > activeIndex - limitDelta &&
		currentIndex < activeIndex + limitDelta
	);
};

const isNormalScale = (
	currentIndex: any,
	activeIndex: any,
	limit: any,
	total: any,
) => {
	const limitDelta = getLimitDelta(limit);

	if (
		(inFirstHalf(activeIndex, limit) && currentIndex < limit - 1) ||
		(inLastHalf(activeIndex, limit, total) &&
			currentIndex >= total + 1 - limit)
	)
		return true;

	return (
		(inFirstHalf(currentIndex, limit) && activeIndex < limitDelta + 1) ||
		(inLastHalf(currentIndex, limit, total) &&
			activeIndex > total - limitDelta - 2) ||
		isAtCenter(currentIndex, activeIndex, limit)
	);
};

type Props = {
	activeIndex: number;
	total: number;
	limit?: number;
	size?: number;
	paginatorType?: 'Default' | 'TimedPaginator';
	activeSlideTimer?: number;
	onClickEvent?: (...args: any) => void;
	tabSize?: number;
	dotSize?: number;
	containerWidthOverride?: number;
	marginOverride?: number;
};

const SwiperIndicator = ({
	activeIndex = 0,
	total,
	limit = 5,
	size = 1,
	paginatorType = 'Default',
	activeSlideTimer = 3000,
	onClickEvent = () => {},
	tabSize = 3.6,
	dotSize = 8,
	containerWidthOverride,
	marginOverride,
}: Props) => {
	const sizeInPx = size * 5;
	const dotsMargin = marginOverride ?? 4;
	const containerWidth =
		containerWidthOverride ?? (sizeInPx + dotsMargin) * limit;
	const translateX = calcTranslateX(
		activeIndex,
		limit,
		total,
		sizeInPx,
		dotsMargin,
	);

	return (
		<StyledDotsContainer $maxWidth={containerWidth}>
			<StyledDotsList $translateX={translateX}>
				{Array.from({ length: total }).map((_, index) => {
					const isDotNormalScale = isNormalScale(
						index,
						activeIndex,
						limit,
						total,
					);
					const isDotActive = index === activeIndex;

					if (paginatorType === 'Default') {
						return (
							<SwiperDot1
								normalScale={isDotNormalScale}
								isActive={isDotActive}
								key={index}
							/>
						);
					}
					return (
						<PaginatorDot
							tabSize={tabSize}
							dotSize={dotSize}
							isActive={isDotActive}
							key={`dot` + index}
							data-num={index}
							data-active={isDotActive}
							onClick={onClickEvent}
							activeSlideTimer={activeSlideTimer}
							$hasWhiteBackground={index < activeIndex} // Highlight previous indexes' dots
							$shouldScaleDown={!isDotNormalScale}
						>
							<span
								aria-hidden={true}
								key={'active' + activeIndex}
							/>
						</PaginatorDot>
					);
				})}
			</StyledDotsList>
		</StyledDotsContainer>
	);
};

const SwiperDot1 = ({
	isActive = false,
	normalScale = false,
	...otherProps
}) => {
	return (
		<StyledDot1
			$active={isActive}
			$scaleDown={!normalScale}
			{...otherProps}
		/>
	);
};
export default SwiperIndicator;
