import styled from 'styled-components';

export const StyledDotsContainer = styled.div<{ $maxWidth: number }>`
	display: flex;
	overflow-x: clip;
	overflow-x: hidden;
	padding: 8px 0;
	max-width: ${({ $maxWidth }) => `${$maxWidth}px`};
`;
export const StyledDotsList = styled.ul<{ $translateX: number }>`
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	list-style-type: none;
	transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
	transform: translateX(-${({ $translateX }) => `${$translateX}px`});
`;
export const StyledDot1 = styled.button<{
	$active: boolean;
	$scaleDown: boolean;
}>`
	padding-inline: 0;
	padding-block: 0;
	background: rgb(255, 255, 255);
	border-radius: 50%;
	height: 5px;
	width: 5px;
	margin-left: 2px;
	margin-right: 2px;
	opacity: ${({ $active }) => ($active ? 1 : 0.6)};
	transition: transform 0.2s ease-in-out,
		opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
	transform: scale(${({ $scaleDown }) => ($scaleDown ? 0.8 : 1)});
`;
