import React from 'react';
import styled from 'styled-components';

const DummyImage = styled.div`
	position: relative;
	width: 100%;
	min-height: 11rem;
	overflow: hidden;
	border-radius: 0.25rem;
	align-self: flex-start;
`;

const DummyContentWrapper = styled.div`
	width: 100%;
	display: grid;
	grid-gap: 0.5rem;

	* {
		border-radius: 0.1875rem;
	}
`;

const DummyL1Booster = styled.div`
	width: 80%;
	height: 0.4375rem;
`;

const DummyTitle = styled.div`
	width: 100%;
	height: 0.625rem;
`;

const DummyL2Booster = styled.div`
	width: 40%;
	height: 0.625rem;
`;

const DummyPrice = styled.div`
	width: 25%;
	height: 0.6875rem;
	margin-top: 0.25rem;
`;

const DummyCard = styled.div`
	min-width: 17.625rem;
	min-height: 14.375rem;
	margin-right: 0.625rem;
	display: grid;
	grid-gap: 0.75rem;

	${DummyImage},
	${DummyL1Booster},
	${DummyTitle},
	${DummyL2Booster},
	${DummyPrice} {
		animation: skeleton-shine 1.8s linear infinite forwards;
		background: #f9f7f7;
		background: linear-gradient(
			to right,
			#e6e5e5 8%,
			#f9f7f7 38%,
			#e6e5e5 54%
		);
		background-size: 1000px 640px;
	}

	@keyframes skeleton-shine {
		0% {
			background-position: -468px 0;
		}
		100% {
			background-position: 468px 0;
		}
	}
`;

const DummyProductCard = ({ className = '' }: { className?: string }) => (
	<DummyCard data-qa-marker='dummy-product-card' className={className}>
		<DummyImage />
		<DummyContentWrapper>
			<DummyL1Booster />
			<DummyTitle />
			<DummyL2Booster />
			<DummyPrice />
		</DummyContentWrapper>
	</DummyCard>
);

export default DummyProductCard;
